
import { Component, Vue } from 'vue-property-decorator';
import TextField from '@/ui-components/TextField/TextField.vue';
import MultiSelect from '@/ui-components/MultiSelect/MultiSelect.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import SplitLayout from '../components/SplitLayout.vue';
import RegisterForm from './components/RegisterForm.vue';
import { Getter } from 'vuex-class';
import { UserRouteNames } from '@/types/users';
import namespaces from "@/store/namespaces";

@Component({
  components: {
    IButton,
    MultiSelect,
    TextField,
    SplitLayout,
    RegisterForm,
  },
})
export default class Register extends Vue {
  @Getter("userRouteNames", { namespace: namespaces.AuthModule })
  public userRouteNames!: UserRouteNames;

  get isMobileView(){
    return this.$vuetify.breakpoint.smAndDown
  }
  public onRegisterSuccess() {
    this.$router.push(this.userRouteNames.DASHBOARD);
  }
}
