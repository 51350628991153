
import { Component, Vue, Prop } from 'vue-property-decorator';
import TextField from '@/ui-components/TextField/TextField.vue';
import MultiSelect from '@/ui-components/MultiSelect/MultiSelect.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import { rules } from '@/validation-rules';
import { countries } from '@/statics/countries';
import { UserRegistrationData } from '@/types/users';
import { Country } from '@/types/country';
import { errors } from '@/statics/errors';
import { Action } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { publicRouteNames } from '@/route-names/public';
import SnackBar from '@/ui-components/SnackBar/SnackBar.vue';
import { getUserByCompanyName, getUserInfoByMobileNumber, getUserInfoByVatNumber } from '@/actions/users/actions';
import { ShowSnackbar } from '@/types/snackbar';

@Component({
  name: 'RegisterForm',
  components: {
    IButton,
    MultiSelect,
    TextField,
    SnackBar,
  },
})
export default class RegisterForm extends Vue {
  @Prop({ required: true }) onSuccess!: () => {};
  @Prop({ type: Boolean, default: true }) withBack!: boolean;

  public valid = false;
  public loading = false;
  public showPassword = false;
  public userExistsError = false;
  public mobileNumberAlreadyRegisteredError = false;
  public companyNameAlreadyRegisteredError = false;
  public vatNumberAlreadyRegisteredError = false;
  public countries = countries;

  public eyeIcon = '/assets/icons/eye-o.svg';
  public eyeSlashIcon = '/assets/icons/eye-slash-o.svg';

  public snackbarText = '';
  public snackbarColor = 'danger';

  public newUser: UserRegistrationData = {
    name: '',
    email: '',
    companyName: '',
    vatNumber: '',
    password: '',
    countryCode: '+966',
    phoneNumber: '',
  };

  public get emailRules() {
    return [
      rules.required(this.$i18n.t('please_enter_your_email') as string),
      rules.emailFormat(this.$i18n.t('invalid_email') as string),
      () =>
        !this.userExistsError || this.$i18n.t('email_is_already_registered'),
    ];
  }

  public passwordRules = [
    rules.required(this.$i18n.t('please_enter_your_password') as string),
    rules.shouldHaveEightChars(
      this.$i18n.t('password_must_have_eight_chars') as string
    ),
    rules.shouldHaveUpperAndLowerCaseChars(
      this.$i18n.t('password_must_have_upper_lower_case_chars') as string
    ),
  ];


  public nameRules = [
    rules.required(this.$i18n.t('please_enter_your_name') as string),
  ];

  public get companyNameRules() {
    return [
      rules.required(this.$i18n.t('please_enter_your_company_name') as string),
      () =>
        !this.companyNameAlreadyRegisteredError ||
        this.$i18n.t('company_name_is_already_registered'),
    ]
  };
  public get phoneNumberRules() {
    return [
      rules.required(this.$i18n.t('please_enter_your_mobile_number') as string),
      rules.saudiPhoneFormat(this.$i18n.t('phone_not_saudi_valid') as string),
      () =>
        !this.mobileNumberAlreadyRegisteredError ||
        this.$i18n.t('mobile_number_is_already_registered'),
    ];
  }

  public get vatNumberRules() {
    return [
      rules.required(this.$i18n.t('please_enter_vat_number') as string),
      () =>
        !this.vatNumberAlreadyRegisteredError ||
        this.$i18n.t('vat_number_is_already_registered').toString(),
      rules.minLength(15, this.$i18n.t('vat_number_min_length').toString())
    ];
  }

  @Action('showSnackbar', { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;

  @Action('createAccount', { namespace: namespaces.AuthModule })
  public createAccountAction: any;

  public formatCountryCode({ isoCode, code }: Country) {
    return `(${isoCode}) ${code}`;
  }

  public handleCreateAccountSuccess() {
    this.loading = false;
    this.onSuccess();

    const color = 'success';
    const text = `${this.$t('register_success_message')} &lt; <u>${
      this.newUser.email
    }</u> &gt; ${this.$t('to_confirm')}`
    this.showSnackbar({ text, color });
  }

  public onMobileNumberChange(mobileNumber: string) {
    this.newUser.phoneNumber = mobileNumber.replace(/ /g, '');
    this.mobileNumberAlreadyRegisteredError = false;
  }

  public onCompanyNameChange() {
    this.companyNameAlreadyRegisteredError = false;
  }

  public onVatNumberChange() {
    this.vatNumberAlreadyRegisteredError = false;
  }

  public async checkMobileNumberRegisteration(mobileNumber: string) {
    const user = await getUserInfoByMobileNumber(mobileNumber);
    if (user) {
      throw new Error('MOBILE_NUMBER_ALREADY_REGISTERED');
    }
  }

  public async checkVatNumberRegisteration(vatNumber: string) {
    const user = await getUserInfoByVatNumber(vatNumber);
    if (user) {
      throw new Error('VAT_NUMBER_ALREADY_REGISTERED');
    }
  }

  public async checkCompanyNameRegistration(companyName: string) {
    const isCompanyNameEmpty = await getUserByCompanyName(companyName);
    if (!isCompanyNameEmpty) {
      throw new Error('COMPANY_NAME_ALREADY_REGISTERED');
    }
  }

  private registerationErrorSnackbar() {
    const color = 'danger';
    const text = this.$t('register_error') as string;
    this.showSnackbar({ text, color });
  }

  public handleCreateAccountFailure(error: any) {
    try {
      const { code, message } = error;
      const errorCode = code || message;
      const {
        EMAIL_EXIST_ERROR,
        MOBILE_NUMBER_ALREADY_REGISTERED,
        VAT_NUMBER_ALREADY_REGISTERED,
        COMPANY_NAME_ALREADY_REGISTERED,
      } = errors;
      const form = this.$refs.form as any;

      if (errorCode === EMAIL_EXIST_ERROR) {
        this.userExistsError = true;
        form.validate();
        return;
      }

      if (errorCode === MOBILE_NUMBER_ALREADY_REGISTERED) {
        this.mobileNumberAlreadyRegisteredError = true;
        form.validate();
        return;
      }

      if (errorCode === COMPANY_NAME_ALREADY_REGISTERED) {
        this.companyNameAlreadyRegisteredError = true;
        form.validate();
        return;
      }

      if (errorCode === VAT_NUMBER_ALREADY_REGISTERED) {
        this.vatNumberAlreadyRegisteredError = true;
        form.validate();
        return;
      }

      this.registerationErrorSnackbar();
    } catch (error) {
      this.registerationErrorSnackbar();
    }
  }

  public async createAccount() {
    try {
      this.loading = true;
      await this.checkMobileNumberRegisteration(this.newUser.phoneNumber);
      await this.checkVatNumberRegisteration(this.newUser.vatNumber);
      await this.checkCompanyNameRegistration(this.newUser.companyName);
      await this.createAccountAction(this.newUser);
      this.handleCreateAccountSuccess();
    } catch (error) {
      this.handleCreateAccountFailure(error);
    } finally {
      this.loading = false;
    }
  }

  public back() {
    this.$router.push({
      ...publicRouteNames.LOGIN,
    })
  }
}
