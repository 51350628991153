import { Country } from '@/types/country';

export const countries: Country[] = [
  {
    id: '0',
    name: 'saudi_arabia',
    isoCode: 'KSA',
    code: '+966',
    mapZoomLevel: { mobile: 3.65, desktop: 5 },
    coordinates:{
      lat: 23.8859,
      lng: 45.0792,
    }
  }
]
